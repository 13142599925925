body {
    font-size: .875rem;
}

main {
    min-height: 100vh;
}
  
.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/*
* Sidebar
*/

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    /*padding: 48px 0 0;  Height of navbar */
    /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); */
    /* background-image: linear-gradient(45deg, #04061a, #060b39); */
    /* background-image: linear-gradient(45deg, #aaaaaa42, #ffffff) */
    background-color: #fff;
}
.sidebar-top .logo-img {
    max-width: 100%;
}
.sidebar-close {
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
    position: absolute;
    width: 100%;
    z-index: 3;
}
.sidebar-sticky {
    position: relative;
    top: 0;
    height: 100vh; /*calc(100vh - 48px);*/
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}
.sidebar .display-photo-wrapper > div {
    width:70px;
    height:70px;
    overflow:hidden;
}
.sidebar .display-photo-wrapper img {
    position: absolute;
    max-width: 100%;
    margin: auto !important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.sidebar .nav {
    font-size: .85rem;
}
.sidebar .nav-link {
    color: var(--color-darkgrey);
    padding: 0 1rem;
}
.sidebar .nav > li > .nav-link {
    margin-bottom: .25rem;
}
.sidebar .nav-link i {
    margin-right: 10px;
}
.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}
.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}
.sidebar .nav > a {
    margin-bottom: 3px;
    color: #213b75;
}
.sidebar .nav > a.active > li > .nav-link > i,
.sidebar .nav > li.active > .nav-link > i,
.sidebar .nav > li.active .sub-menu.active > a > i {
    color: var(--color-primary);
}
.sidebar .nav > a.active > li > .nav-link,
.sidebar .nav > li.active > .nav-link {
    color: var(--color-primary);
    font-weight: 500;
    border-right: 5px solid var(--color-primary);
}
.sidebar .nav > a > li > .nav-link:hover > div {
    background-color: var(--color-lightgrey);
}
.sidebar .sidebar-bottom .nav > a.active > li > .nav-link,
.sidebar .sidebar-bottom .nav > li.active > .nav-link {
    border-right: 0px;
}
.sidebar .sidebar-bottom .nav > a > li > .nav-link,
.sidebar .sidebar-bottom .nav > li > .nav-link {
    margin-right: 5px;
}
.sidebar li.active .sub-menu > .nav-link,
.sidebar .nav li ul.active > .nav-link {
    background-color: var(--color-secondary);
    border-radius: 5px 5px 0px 0px;
}
.sidebar .nav li ul:not(.active) > .nav-link {
    border-radius: 5px;
}
.sidebar .sub-menu {
    padding: 0px;
    margin-bottom: .25rem;
}
.sidebar .sub-menu .section.collapsible {
    height: 0px;
}
.sidebar .sub-menu-items {
    transition: all ease-in-out 0.25s;
    background-color: var(--color-lightgrey);
    overflow: hidden;
}
.sidebar .sub-menu-items a.active > li,
.sidebar .sub-menu-items > li.active {
    padding: 0px;
    background-color: var(--color-darkgrey);
}
.sidebar-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.sidebar .sidebar-menu-header {
    padding: 0px 1.5rem;
}


/*
* Navbar
*/

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}
.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}
.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}
.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.navbar-nav > li {
    display: flex;
    align-self: center;
    font-weight: 500;
}


/** Reponsiveness */
@media (max-width: 1380px) {

}

@media (max-width: 1270px) {
    
}

@media (max-width: 1056px) {

}
  
  