/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 

}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    /** Sidebar **/
    nav.sidebar {
        max-width: 250px;
        z-index: 9999;
        transition: all .3s ease-in-out;
        left: -300px;
    }
    nav.sidebar.expanded {
        left: 0px;
    }

    /** Navbar */
    nav.navbar {
        justify-content: space-between !important ;
    }

    /** Table */
    table.table {
        font-size: .75rem;
    }

    /** Modal */
    .modal-dialog {
        max-width: unset !important;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
    
}