/* Aligments */
.center-xy {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.center-y {
    display: flex;
    align-items: center;
    height: 100%;
}
.col-center {
	margin: 0 auto !important;
	float: none !important;
	display: block !important;
}
.d-table .vertical-center {
    display: table-cell;
    vertical-align: middle;
}

/* Typography */
.text-uppercase {text-transform: uppercase}
.text-lowercase {text-transform: lowercase}
.text-capitalize {text-transform: capitalize}

/* Text Color */
.text-white {
    color: #fff;
}

/* Font Weight */
.text-bold {
    font-weight: bold;
}

/* Overlays */
.overlay-after,
.overlay-before {
    position: relative;;
    overflow: hidden;
}
.overlay-after::after,
.overlay-before::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
}
.overlay-default::after,
.overlay-default::before {
    background: linear-gradient(to bottom,rgba(255,255,255,0.3),#2d617c);
    opacity: .8;
}
.overlay-dark::after,
.overlay-dark::before {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.35),#000);
    opacity: .6;
}
.overlay-dark-reverse::after,
.overlay-dark-reverse::before {
    background: linear-gradient(to top, #000,rgba(0, 0, 0, 0.35));
    opacity: .4;
}
.overlay-black::after,
.overlay-black::before {
    background: #000;
    opacity: .8;
}
.overlay-bright::after,
.overlay-bright::before {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.75),#fff);
    opacity: .8;
}
.overlay-bright-reverse::after,
.overlay-bright-reverse::before {
    background: linear-gradient(to bottom,#fff, rgba(255, 255, 255, 0.75));
    opacity: .8;
}
.overlay-child {
    position: relative;
    z-index: 3;
}

/* Visibility */
.hidden {display:none;}

/* Cursor */
.cursor-pointer {
    cursor: pointer;
}

/* Pointer */
.no-pointer-events {
    pointer-events: none;
}

/** Utils */
.dropdown.no-caret .dropdown-toggle::after {
    display: none;
}
.hideable {
    transition: all 0.5s !important;
    height: 25px;
    opacity: 1;
}
.hide {
    height: 0px;
    opacity: 0;
}
.card-link,
.card-link:hover {
    color: unset !important;
}
i.disabled {
    pointer-events: none;
    opacity: 0.5;
}

/** On Screen Only */
@media only screen {
    .hide-on-screen {
        display: none !important;  
    }
}

/** On Print Only */
@media only print {
    .print-wrapper {
        padding: 50px;
    }
}